import React from 'react';
import Layout from '~layouts/layout';
import { RenderedContext } from '~layouts/layout';
import Header from '~2-components/Header/Header';
import { searchData } from '~data/searchData';
import PageSection from '~2-components/PageSection/PageSection';
import Search from '~/2-components/Search/Search';

const ExampleSearchPage = () => (
    <Layout>
        <RenderedContext.Provider value={searchData}>
            <Header />
        </RenderedContext.Provider>

        <div className="page-content">
            <div className="container">
                <PageSection>
                    <Search />
                </PageSection>
            </div>
        </div>
    </Layout>
);

export default ExampleSearchPage;
