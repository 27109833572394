export const searchData = {
    title: 'Search',
    breadcrumbs: [
        { title: 'Home', pageSlug: 'homepage' },
        {
          title: 'Search',
          pageSlug: 'search',
          isActive: true
        }
    ],
    hasImage: false
};
